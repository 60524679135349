import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { DealCard } from './DealCard'
import DealsFilter from './DealsFilter'
import { useHistory } from 'react-router-dom'
import { CurrencyValue } from '../shared/TextMask'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
    justifyItems: 'center',
  },

  noDeals:{
    backgroundColor: '#A0A0A0',
    justifyItems: 'center',
    padding: '20px',
    borderRadius: '10px'
  },
  actionArea: {
    borderRadius: 16,
    height: '100%',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    height: '100%',
    borderRadius: 16,
    boxShadow: 'none',
    '&:hover': {
      // boxShadow: `0 6px 12px 0 ${Color(color)
      //   .rotate(-12)
      //   .darken(0.2)
      //   .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: '#0089a1',
      padding: '1rem 1.5rem 1.5rem',
      textAlign: 'center',
      height: '100%'
    };
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    textTransform: 'uppercase',
  },
  subtitle: {
    color: 'white',
    opacity: 0.87,
    marginTop: '1.2rem',
    fontWeight: 500,
    fontSize: 20,
  },
  image: {
    minHeight: 200
  }
}));

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('md')]: {
      // justifyContent: 'center',
    },
  },
}));


export default function SpecialDealsComponent() {
  const classes = useStyles();
  const gridStyles = useGridStyles({ color: '#ff9900' });
  const lastDealRef = useRef();
  const history = useHistory();
  const sortDirection = 'Desc';
  const pageNumber = 1;

  const {
    loading,
    deals
  } = DealsFilter(pageNumber, sortDirection)

  const cleanModel = (model) => {
    return model.toLowerCase().replace('/', '-')
  }

  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          <div className={classes.background}> 
            <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
              <Box fontWeight={500} mb={5} style={{ textTransform: 'capitalize' }}>Current Special Deals</Box>
            </Typography>
            <Grid classes={gridStyles} container spacing={4}>
              {
                (!loading && deals.length > 0) &&
                deals.map((deal) =>
                  <Grid item key={deal.id} xs={12} sm={6} md={4} style={{ minHeight: 400 }} >
                    <DealCard ref={lastDealRef}
                      onClick={() => history.push(`models/${cleanModel(deal.model)}/${deal.id}`)}
                      classes={classes}
                      title={deal.headline}
                      subtitle={<CurrencyValue value={parseInt(deal.monthlyInstallment)} />}
                      image={deal.imageUrl}
                    />
                  </Grid>
                )
              }
              {
                (!loading && deals.length === 0) &&
                <Grid item xs={12}>
                  <div className={classes.noDeals}>
                  <Typography gutterBottom variant="h6" color="textPrimary" textAlign="center" fontWeight="500">
                    <Box style={{ textTransform: 'capitalize' }}>There are currently no deals available</Box>
                  </Typography>
                  </div>
                </Grid>
              }
              {
                loading &&
                <Grid item container justify='center' alignContent='center' xs={12}>
                  <CircularProgress size={80} />
                </Grid>
              }
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}