import React, { useContext } from 'react';
import { Route, useLocation } from 'react-router-dom';
import NavbarComponent from './NavbarComponent'
import HomeComponent from './homePage/HomeComponent'
import FooterComponent from './homePage/FooterComponent'
import ApplyFinance from './applyFinancePage/ApplyFinance'
import PreApplication from './applyFinancePage/PreApplication'
import PostMfc from './applyFinancePage/PostMfc'
import PQSuccess from './applyFinancePage/PQSuccess'
import PQDecline from './applyFinancePage/PQDecline'
import AfterSalesComponent from './afterSalesPages/AfterSalesComponent'
import SpecialDealsComponent from './specialDeals/SpecialDealsComponent'
import CarProfileContainer from './carProfile/CarProfileContainer'
import OurVehicles from './ourVehiclesPage/OurVehicles';
import ContactUs from './contactUsPage/ContactUs';
import Service from './servicePage/Service';
import About from './aboutUs/aboutUs';
import Parts from './partsPage/Parts';
import Terms from './legalPage/Terms';
import Privacy from './legalPage/Privacy';
import Cookies from './legalPage/Cookies';
import Popia from './legalPage/Popia';
import ScrollUpButton from "react-scroll-up-button";
import Complaints from "./legalPage/Complaints";
import WarrantyAndServicePlan from "./legalPage/Warranty-Service.js";
import ConflictsOfInterest from "./legalPage/Conflicts.js";
import UsedCarUsedVehiclesPage from "./usedCarUsedVehiclesPage/usedCarUsedVehiclesPage";
import TradeIn from "../components/shared/TradeInContainer";
import { MetaTags } from '@red-build/leadcentre';
import { DealerContext } from './shared/contexts/DealerContext';
import ShowRoom from './Showroom/Showroom';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';

export default function DashboardContainer() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <main>
        <NavbarComponent />
        <Route exact
          path="/"
          render={(props) => <HomeComponent />} />
        <Route exact path='/apply-for-finance' component={ApplyFinance} />
        <Route exact path='/apply-for-finance/done/:leadId' component={PreApplication} />
        <Route exact path='/pqComplete/leadId/:leadId/a/:a/i/:i' component={PostMfc} />
        <Route exact path='/pqSuccess/:leadId' component={PQSuccess} />
        <Route exact path='/:dealerId/0/buy/pqDecline/:leadId' component={PQDecline} />
        <Route path={['/maintenance', '/warranty', '/tomtom-live', '/service-plans', '/bodyshops', '/roadside-assistance']} render={props => <AfterSalesComponent {...props} />} />
        <Route exact
          path="/special-deals"
          render={(props) => <SpecialDealsComponent />} />
        {/*<Route exact path="/new/:model/" component={CarProfileContainer}/>*/}
        <Route exact path="/models/:carId/:dealId?" component={CarProfileContainer} />
        <Route exact path="/requestergws" component={Popia} />
        <Route path='/our-vehicles' component={OurVehicles} />
        <Route exact path='/used-vehicles' component={UsedCarUsedVehiclesPage} />
        <Route exact path='/special-offers' component={SpecialDealsComponent} />
        <Route exact path="/used-vehicles/:stockId" component={UsedCarUsedVehiclesPage} />
        <Route exact path='/used-vehicles/dealer/:dealerId' component={UsedCarUsedVehiclesPage} />
        <Route path='/TradeIn' component={TradeIn} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/service' component={Service} />
        <Route path='/about-us' component={About} />
        <Route path='/parts' component={Parts} />
        <Route path='/terms' component={Terms} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/cookies' component={Cookies} />
        <Route path='/complaints' component={Complaints} />
        <Route path='/warranty-and-service-plan' component={WarrantyAndServicePlan} />
        <Route path='/conflicts-of-interest' component={ConflictsOfInterest} />
        <Route path='/showroom' component={ShowRoom} />
        <Route path="/show-room/:model/:stockId" render={() => <DetailedVehicle />} />
        <FooterComponent />
        <ScrollUpButton />
        <MetaTags dealer={globalDealer} pathname={pathname} />
      </main>
    </React.Fragment>
  );
}